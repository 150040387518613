import React from "react";

export default ({ color, height }) => (
	<svg width="56" height="84" viewBox="0 0 56 84" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6179 0C35.2444 0 42.1489 3.09131 47.1467 8.08887C52.1443 13.0869 55.2356 19.9912 55.2356 27.6177C55.2356 41.4766 32.5835 76.2427 27.6179 83.6978C22.6523 76.2427 0 41.4766 0 27.6177C0 19.9912 3.09131 13.0869 8.08887 8.08887C13.0867 3.09131 19.9912 0 27.6179 0ZM5 19.2974V18.6025C4.93066 18.8223 4.92627 19.064 5 19.2974Z" fill="black" />
		<path d="M28.9275 39.0865C35.1822 38.5476 39.818 33.0152 39.2818 26.7294C38.7456 20.4437 33.2404 15.7849 26.9858 16.3238C20.7311 16.8627 16.0953 22.3951 16.6315 28.6809C17.1677 34.9666 22.6729 39.6253 28.9275 39.0865Z" fill="white" />
		<path d="M37.2946 33.2113C42.412 32.7704 46.2049 28.2438 45.7662 23.1009C45.3275 17.9581 40.8233 14.1464 35.7058 14.5873C30.5884 15.0282 26.7955 19.5547 27.2342 24.6976C27.6729 29.8404 32.1771 33.6521 37.2946 33.2113Z" fill="#FFB000" />
	</svg>


);
